import React, { useEffect, useState } from "react";
import Loader from "../../components/common/loader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../components/common/breadCrumb";
import { dateFormatChange } from "../../utils/helpers/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  addSeniorTaskSheet,
  editSeniorTaskSheet,
  listOfSeniorProject,
  listOfSeniorTaskSheet,
  listofjunior,
} from "../../api/tasksheetApi";
import { listOfAssignCourse } from "../../api/itpApi";
import { getId, getName } from "../../utils/helpers/custom";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
const TasksheetAddEdit = () => {
  const { tasksheet_id } = useParams();
  const [loading, setLoading] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setendDate] = useState();
  const [isedit, setisedit] = useState();
  const [selectedMultiDeveloper, setSelectedMultiDeveloper] = useState();
  const [editTaskSheetData, setEditTaskSheetData] = useState("");
  const [listOfEmployees, setlistOfEmployees] = useState("");
  const [listOfDataState, setListOfDataState] = useState();
  const [selectedProject, setSelectedProject] = useState([]);
  const [traineeCourseList, setTraineeCourseList] = useState();
  const [selectedCourse, setSectedCourse] = useState();
  const [selectedCourseName, setSelectedCourseName] = useState();
  const [selectedMinutes, setSelectedMinutes] = useState();
  const [selectedMinutesOption, setSelectedMinutesOption] = useState();
  const [courseId, setCourseId] = useState();
  const id = getId();
  const [existingUser, setExistingUser] = useState({
    label: getName(),
    value: id,
  });
  document.title = pageTitle;
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (!isNaN(tasksheet_id)) {
      setisedit(true);
      setPageTitle("Edit Tasksheet");
      setLoading(true);
      listOfSeniorTaskSheet({
        id: tasksheet_id,
      })
        .then((res) => {
          setEditTaskSheetData(res?.data?.data?.listOfSeniorTaskSheet[0]);
          setSelectedProject({
            value: res?.data?.data?.listOfSeniorTaskSheet[0]?.projectName,
            label: res?.data?.data?.listOfSeniorTaskSheet[0]?.projectName,
          });
          setSelectedMultiDeveloper({
            value: res?.data?.data?.listOfSeniorTaskSheet[0]?.assignTo,
            label:
              res?.data?.data?.listOfSeniorTaskSheet[0]?.authUser
                ?.developerName,
          });
          setSectedCourse({
            value: res?.data?.data?.listOfSeniorTaskSheet[0]?.projectName,
            label: res?.data?.data?.listOfSeniorTaskSheet[0]?.projectName,
          });
          setSelectedCourseName({
            value: res?.data?.data?.listOfSeniorTaskSheet[0]?.courseId,
            label:
              res?.data?.data?.listOfSeniorTaskSheet[0]?.course?.courseName,
          });
          setSelectedMinutes({
            value: res?.data?.data?.listOfSeniorTaskSheet[0].minutes,
            label: res?.data?.data?.listOfSeniorTaskSheet[0].minutes,
          });
        })
        .catch((err) => {
          setLoading(false);
          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setisedit(false);
      setPageTitle("Add Tasksheet");
    }

    listofjunior()
      .then((res) => setlistOfEmployees(res?.data?.data))
      .catch((err) => err);

    listOfSeniorProject({
      condition: {
        assignTo: id,
      },
    })
      .then((response) => {
        setListOfDataState(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });

    listOfAssignCourse({
      assignTo: parseInt(1),
    })
      .then((response) => {
        setTraineeCourseList(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tasksheet_id]);

  const developerObj = Array.isArray(listOfEmployees)
    ? listOfEmployees?.map((res) => {
        return {
          value: `${res?.id}`,
          label: `${res?.developerName} `,
        };
      })
    : null;

  const selectedDeveloperObj = Array.isArray(selectedMultiDeveloper)
    ? selectedMultiDeveloper?.map((res) => {
        return {
          value: `${res?.id}`,
          label: `${res?.developerName}`,
        };
      })
    : null;

  function handleMultiDevelop(item) {
    validation.setFieldValue("assignto", item.value);
    setSelectedMultiDeveloper(item);
  }

  const projectObj = listOfDataState
    ? [
        ...listOfDataState.map((res) => ({
          value: `${res?.project?.id}`,
          label: `${res?.project?.projectName}`,
        })),
        { value: "trainee", label: "Trainee" },
      ]
    : [];

  const selectedProjectObj = Array.isArray(selectedCourse)
    ? selectedCourse?.map((res) => {
        return {
          value: `${res?.id}`,
          label: `${res?.developerName}`,
        };
      })
    : null;

  function handleMultiProject(selectedCourse) {
    validation.setFieldValue("projectName", selectedCourse?.label);
    setSelectedProject(selectedCourse);
    setSectedCourse(selectedCourse);
  }

  let editTaskSheetHours = isedit
    ? String(editTaskSheetData?.hours)
    : editTaskSheetData?.hours;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: editTaskSheetData?.projectName || "",
      projectTask: editTaskSheetData?.taskName || "",
      taskDetails: editTaskSheetData?.taskDetails || "",
      date: isedit
        ? `${editTaskSheetData?.startDate} to ${editTaskSheetData?.endDate}`
        : "",
      hours: editTaskSheetHours || "",
      minutes: editTaskSheetData?.minutes || "",
      assignto: editTaskSheetData?.assignTo || id || "",
      courseName: editTaskSheetData?.courseName || "",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().required(RequiredField("Project Name")),
      projectTask: Yup.string().required(RequiredField("Task Name")),
      taskDetails: Yup.string().required(RequiredField("Details Of Task")),
      date: Yup.string().required(RequiredField("Date")),
      hours: Yup.string().required(RequiredField("Hour")),
      minutes: Yup.string().optional(),
      assignto: Yup.string().required(RequiredField("Developer's Name")),
    }),
    onSubmit: (value) => {
      setLoading(true);
      if (isedit) {
        setLoading(true);

        editSeniorTaskSheet({
          id: parseInt(tasksheet_id),
          projectName: value?.projectName,
          taskName: value?.projectTask,
          taskDetails: value?.taskDetails,
          startDate: startDate,
          endDate: endDate,
          hours: +value?.hours,
          minutes: +value?.minutes,
          assignTo: value?.assignto,
        })
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              setSelectedMultiDeveloper("");
              setSelectedProject(" ");
              navigate("/tasksheet");
              setLoading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        const payload = {
          addedBy: id,
          projectName: value?.projectName || "",
          taskName: value?.projectTask,
          taskDetails: value?.taskDetails,
          startDate: startDate,
          endDate: endDate,
          hours: +value?.hours,
          minutes: +value?.minutes,
          assignTo: value?.assignto,
        };

        if (
          selectedProject?.value === "Trainee" ||
          selectedProject?.value === "trainee"
        ) {
          payload.courseId = courseId;
        }

        addSeniorTaskSheet(payload)
          .then((res) => {
            if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
              toast.success(res?.data?.message);
              validation.resetForm();
              setSelectedMultiDeveloper("");
              setSelectedProject(" ");
              navigate("/tasksheet");
              setLoading(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            return err;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const assignCourseName = traineeCourseList?.map((res) => {
    return {
      value: `${res?.course?.id}`,
      label: `${res?.course?.courseName}`,
    };
  });

  let minutesOption = [
    {
      label: "00",
      value: "00",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "45",
      value: "45",
    },
    {
      label: "60",
      value: "60",
    },
  ];

  return (
    <div className="page-content">
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <BreadCrumb title={pageTitle} pageTitle="HRMS" />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">{pageTitle}</h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <Link to={"/tasksheet"} className="btn btn-primary">
                  Back
                </Link>
              </div>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Form id="creattask-form" onSubmit={validation.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label className="form-label">Project Name :</label>

                      <Select
                        placeholder=" Select Project Name"
                        className={` ${
                          validation.errors.projectName &&
                          "is-invalid red-border"
                        }`}
                        value={selectedProjectObj || selectedProject}
                        isMulti={false}
                        onChange={(e) => {
                          handleMultiProject(e);
                        }}
                        options={projectObj}
                        onBlur={validation.handleBlur}
                        name="projectName"
                      />
                      {validation.touched.projectName &&
                      validation.errors.projectName ? (
                        <div className="invalid-feedback">
                          {validation.errors.projectName}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  {selectedProject?.value === "Trainee" ||
                  selectedProject?.value === "trainee" ? (
                    <Col lg={6}>
                      <div className="mb-3">
                        <label className="form-label">Course Name :</label>
                        <Select
                          name="courseName"
                          value={
                            validation?.values?.courseName || selectedCourseName
                          }
                          onChange={(selectedOption) => {
                            validation.setFieldValue(
                              "courseName",
                              selectedOption?.id
                            );
                            setCourseId(selectedOption?.value);
                          }}
                          options={assignCourseName}
                          className={
                            validation.touched.courseName &&
                            validation.errors.courseName
                              ? "is-invalid"
                              : ""
                          }
                        />
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Task Name :
                      </label>
                      <Input
                        type="text"
                        id=""
                        className="form-control"
                        placeholder="Enter Your Task Name"
                        name="projectTask"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.projectTask || ""}
                        invalid={
                          !!(
                            validation.touched.projectTask &&
                            validation.errors.projectTask
                          )
                        }
                      />
                      {validation.touched.projectTask &&
                      validation.errors.projectTask ? (
                        <FormFeedback type="invalid">
                          {validation.errors.projectTask}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="JoiningdatInput" className="form-label">
                        Date :
                      </Label>
                      <Flatpickr
                        id="datepicker-publish-input"
                        className={`form-select ${
                          validation.errors.date && "is-invalid"
                        }`}
                        placeholder="Select a date"
                        name="date"
                        validate={{ required: { value: true } }}
                        onBlur={validation.handleBlur} // this all comments are needed
                        invalid={
                          !!(
                            validation?.touched?.date &&
                            validation?.errors?.date
                          )
                        }
                        options={{
                          mode: "range",
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          defaultDate: [
                            editTaskSheetData?.startDate,
                            editTaskSheetData?.endDate,
                          ],
                          onChange: function (
                            selectedDates,
                            dateStr,
                            instance
                          ) {
                            validation.setFieldValue("date", dateStr);
                            setStartDate(dateFormatChange(selectedDates[0]));
                            setendDate(dateFormatChange(selectedDates[1]));
                          },
                        }}
                      />
                      {validation.touched.date && validation.errors.date ? (
                        <div className="invalid-feedback">
                          {validation.errors.date}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-hour-input" className="form-label">
                        Estimated Time :
                      </label>

                      <div className="input-group">
                        <Row>
                          <Col lg={6}>
                            <input
                              type="tel"
                              name="hours"
                              id="task-hour-input"
                              className={`form-control ${
                                validation.errors.hours &&
                                validation.touched.hours
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Hours"
                              value={validation?.values?.hours || ""}
                              onChange={(e) => {
                                const input = e?.target?.value?.replace(
                                  /\D/g,
                                  ""
                                );
                                e.target.value = input;
                                validation.handleChange(e);
                              }}
                              onBlur={validation.handleBlur}
                              maxLength={2}
                              invalid={
                                !!(
                                  validation.touched.hours &&
                                  validation.errors.hours
                                )
                              }
                            />
                            {validation.touched.hours &&
                            validation.errors.hours ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hours}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            {" "}
                            <Select
                              className={` ${
                                validation.errors.minutes &&
                                "is-invalid red-border"
                              }`}
                              placeholder="Enter minutes"
                              value={selectedMinutesOption || selectedMinutes}
                              isMulti={false}
                              onChange={(selectedOption) => {
                                setSelectedMinutesOption(selectedOption);
                                validation.setFieldValue(
                                  "minutes",
                                  selectedOption ? selectedOption.label : null
                                );
                              }}
                              options={minutesOption}
                              onBlur={validation.handleBlur}
                              name="minutes"
                            />{" "}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Devloper Name :
                      </label>
                      <Select
                        placeholder=" Select Developer Name"
                        className={` ${
                          validation.errors.assignto && "is-invalid red-border"
                        }`}
                        value={
                          selectedDeveloperObj ||
                          selectedMultiDeveloper ||
                          existingUser
                        }
                        isMulti={false}
                        onChange={(e) => {
                          handleMultiDevelop(e);
                        }}
                        options={developerObj}
                        onBlur={validation.handleBlur}
                        name="assignto"
                      />
                      {validation.touched.assignto &&
                      validation.errors.assignto ? (
                        <div className="invalid-feedback">
                          {validation.errors.assignto}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <label htmlFor="task-title-input" className="form-label">
                        Details Of Task :
                      </label>
                      <Input
                        type="textarea"
                        id="task-title-input"
                        className="form-control"
                        placeholder="Enter Your Task Details"
                        name="taskDetails"
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.taskDetails || ""}
                        invalid={
                          !!(
                            validation.touched.taskDetails &&
                            validation.errors.taskDetails
                          )
                        }
                      />
                      {validation.touched.taskDetails &&
                      validation.errors.taskDetails ? (
                        <FormFeedback type="invalid">
                          {validation.errors.taskDetails}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="addNewMember">
                      {"Save "}
                    </button>
                  </div>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default TasksheetAddEdit;
