import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/logo/logo-light.jpg";
import { updatePassword } from "../../api/forgetPasswordApi";
import {
  RequiredField,
} from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";

const ForgetPassword = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(RequiredField("Email"))
        .matches(emailRegex, "Please enter a valid email address."),
      newPassword: Yup.string().required(RequiredField("Password")),
      confirmPassword: Yup.string()
        .test("password-match", "Passwords must match", function (value) {
          return value === this.parent.newPassword;
        })
        .required(RequiredField("Confirm Password")),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setIsLoading(true);
      const payload = {
        email: values.email,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      updatePassword(payload)
        .then((response) => {
          const responseBody = response?.data;
          if (
            responseBody?.statusCode === ResponseStatusEnum.SUCCESS ||
            responseBody?.statusCode === ResponseStatusEnum.ACCEPTED
          ) {
            window.location.href =
              "https://si-hrms-git-develop-structure-sweta-rampariya.vercel.app/";
            toast.success(responseBody?.message);
            resetForm();
          } else {
            toast.error(responseBody?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.data?.message);
          return err;
        })
        .finally(() => setIsLoading(false));
    },
  });

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };

  document.title = "Forget Password";

  return (
    <div className="auth-page-content">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center mt-sm-5 mb-4 text-white-50">
              {/* <div>
                <Link to="/" className="d-inline-block auth-logo">
                 <img src={logoLight} alt="" height="20%" width="12%"/>
                </Link>
              </div> */}
              <div className="login_img">
                  {/* <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20%" width="12%"/>
                  </Link> */}
                </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Enter Your Details!</h5>
                </div>

                <div className="p-2 mt-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <div className="mb-3">
                      <Label htmlFor="email" className="form-label">
                        Email
                      </Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                        }
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="password-input">
                        New Password
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="newPassword"
                          value={validation.values.newPassword || ""}
                          type={passwordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                          }
                        />
                        {validation.touched.newPassword &&
                          validation.errors.newPassword && (
                            <FormFeedback type="invalid">
                              {validation.errors.newPassword}
                            </FormFeedback>
                          )}
                        <button
                          className={`btn btn-link position-absolute ${
                            validation.errors.newPassword ? "err" : "end-0"
                          } top-0 text-decoration-none text-muted`}
                          type="button"
                          id="password-addon"
                          onClick={togglePasswordVisibility}>
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="password-input">
                        Confirm Password
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="confirmPassword"
                          value={validation.values.confirmPassword || ""}
                          type={passwordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                          }
                        />
                        {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword && (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          )}
                        <button
                          className={`btn btn-link position-absolute ${
                            validation.errors.confirmPassword ? "err" : "end-0"
                          } top-0 text-decoration-none text-muted`}
                          type="button"
                          id="password-addon"
                          onClick={togglePasswordVisibility}>
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <Row>
                        <Col>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            disabled={isLoading}
                            color="success"
                            className="btn btn-success w-100"
                            type="submit">
                            {!isLoading ? (
                              "Update Password"
                            ) : (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPassword;
