import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../components/common/breadCrumb";
import { getRole } from "../../utils/helpers/custom";
import * as Yup from "yup";
import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import ApproveRejectModal from "../../components/common/approveRejectModal";
import PreviewCardHeader from "../../components/common/previewCardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  addProject,
  deleteProjectDetails,
  listOfProject,
  updateProject,
} from "../../api/itpProject";
import { ResponseStatusEnum } from "../../utils/helpers/enums";
import { toast } from "react-toastify";
import Select from "react-select";

const ITPProjectDetails = (props) => {
  document.title = "ITP Project create";
  const role = getRole();
  const maxCharacterLimit = 5000;
  const navigate = useNavigate();
  const { itpProjectId } = useParams();

  const [loading, setLoading] = useState(false);
  const [isDevelopMent, setIsDevelopMent] = useState({label: "BE", value: "BE"});
  const [selectedDevelopMent, setSelectedDevelopMent] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [arrayHelpersState, setArrayHelpersState] = useState();
  const [indexState, setIndexState] = useState();
  const [selectedProject, setSelectedProject] = useState([]);
  const [deleteTopicId, setDeleteTopicId] = useState(undefined);

  useEffect(() => {
    arrayHelpersState?.remove(indexState);
  }, [arrayHelpersState, indexState]);

  const itpDelete = (id, arrayHelpers, index) => {
    setDeleteTopicId(id);
    setArrayHelpersState(arrayHelpers);
    setIndexState(index);
  };

  useEffect(() => {
    if (itpProjectId !== undefined) {
      listOfProject({
        itpProjectId,
      })
        .then((res) => {
          if (res?.statusCode === ResponseStatusEnum.SUCCESS) {
            setSelectedProject(res?.data);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [itpProjectId]);

  useEffect(() => {
    if (deleteTopicId !== undefined) {
      setLoading(true);
      deleteProjectDetails({ id: deleteTopicId })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.SUCCESS) {
            arrayHelpersState?.remove(indexState);
            setDeleteTopicId(undefined);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [deleteTopicId]);

  const initialValues = {
    projectName: selectedProject[0]?.projectName || "",
    development: selectedProject[0]?.development || "",
    remark: selectedProject[0]?.remark || "",
    projectDetails:
      selectedProject[0]?.itpProjectDetails.map((detail) => ({
        id: +detail.id || "",
        module: detail.module || "",
        apiEndPoints: detail.apiEndPoints || "",
        apis: detail.apis || "",
        days: detail.days || "",
        screen: detail.screen || "",
      })) || [],
  };

  const validationSchema = Yup.object({
    projectName: Yup.string().required(RequiredField("Project Name")),
    development: Yup.string().required(RequiredField("Development")),
    projectDetails: Yup.array().of(
      Yup.object().shape({
        module: Yup.string().required(RequiredField("Module")),
        apiEndPoints: Yup.string().required(RequiredField("ApiEndPoints")),
        apis: Yup.string().optional(),
        days: Yup.string().required(RequiredField("Day")),
        screen: Yup.string().optional(),
      })
    ),
  });

  const handleSubmit = (values) => {
    if (itpProjectId === undefined) {
      addProject({ ...values })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.CREATE) {
            navigate("/itp-project");
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      const projectDetails = values?.projectDetails.map((res) => {
        return {
          id: res.id,
          module: res.module,
          apiEndPoints: res.apiEndPoints,
          apis: res.apis,
          screen: res.screen,
          days: res.days,
        };
      });
      updateProject({ ...values, itpProjectId: +itpProjectId, projectDetails })
        .then((res) => {
          if (res?.data?.statusCode === ResponseStatusEnum.ACCEPTED) {
            navigate("/itp-project");
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const customToolbarConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
  };

  const developmentData = [
    { label: "BE (Backend)", value: "BE" },
    { label: "FE (Frontend)", value: "FE" },
  ];

  const [openFlush, setOpenFlush] = useState("1");
  const toggleFlush = (id) => {
    if (openFlush !== id) {
      setOpenFlush(id);
    } else {
      setOpenFlush();
    }
  };

  return (
    <React.Fragment>
      <ApproveRejectModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Project Create"
            pageTitle="ITPProject"
            navigation="/itp-project"
          />
          <Row className="mb-4">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">
                ITP(Interns Training Program) Project
              </h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                {role !== "Employee" && (
                  <Link to="/itp-project">
                    <button
                      className="btn btn-primary createTask"
                      type="button">
                      Back
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </Row>
        </Container>
        {loading === false && (
          <Card>
            <PreviewCardHeader title="Project" />
            <CardBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize>
                {({ values, handleSubmit, handleChange, handleBlur }) => (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}>
                    <Row className="g-4 mb-3">
                      <Col
                        lg={itpProjectId ? 12 : 6}
                        md={itpProjectId ? 12 : 6}
                        sm={12}>
                        <div className="mb-1">
                          <label
                            htmlFor="task-title-input"
                            className="form-label">
                            Project Name:
                          </label>

                          <Field
                            className="form-control"
                            placeholder="Enter Project Name"
                            name="projectName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.projectName}
                          />
                          <ErrorMessage
                            name="projectName"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </Col>
                      {itpProjectId === undefined && (
                        <Col lg={6} md={6} sm={12}>
                          <Label
                            htmlFor="task-title-input"
                            className="form-label">
                            Development:
                          </Label>

                          <Select
                            placeholder="Enter development"
                            value={isDevelopMent}
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setIsDevelopMent(selectedOption);
                              setSelectedDevelopMent(selectedOption.value)
                            }}
                            options={developmentData}
                            onBlur={validationSchema.handleBlur}
                            name="development"
                          />
                        </Col>
                      )}

                      <Col lg={12}>
                        <div>
                          <Label htmlFor="remark" className="form-label">
                            <strong>Remark</strong>
                          </Label>

                          <CKEditor
                            editor={ClassicEditor}
                            data={values.remark}
                            config={customToolbarConfig}
                            onChange={(event, editor) => {
                              const data = editor?.getData();
                              const textContent = data?.replace(/<[^>]*>/g, "");
                              if (textContent?.length <= maxCharacterLimit) {
                                handleChange("remark")(data);
                              }
                            }}
                          />
                          <p>
                            Character Count:{" "}
                            {values.remark?.replace(/<[^>]*>/g, "")?.length} /{" "}
                            {maxCharacterLimit}
                          </p>
                          {values.remark?.replace(/<[^>]*>/g, "")?.length >=
                            maxCharacterLimit && (
                            <p className="validateError">
                              Character limit exceeded!
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Card>
                      <PreviewCardHeader title="Project Details" />
                      <CardBody>
                        <FieldArray
                          name="projectDetails"
                          render={(arrayHelpers) => (
                            <div className="live-preview">
                              <Accordion
                                id="project-details-accordion"
                                flush
                                open={openFlush}
                                toggle={toggleFlush}>
                                {values.projectDetails.map((user, index) => (
                                  <AccordionItem key={index}>
                                    <AccordionHeader
                                      className="custom-accordion-header"
                                      targetId={`project-detail-${index}`}>
                                      <Row
                                        xs="2"
                                        style={{ display: "contents" }}>
                                        <Col className="d-flex align-items-center custom_css_font_color">
                                          Module {index + 1}
                                        </Col>
                                        <Col>
                                          <Button
                                            type="button"
                                            color="danger"
                                            onClick={() =>
                                              itpDelete(
                                                user.id,
                                                arrayHelpers,
                                                index
                                              )
                                            }>
                                            <i className="ri-delete-bin-2-fill"></i>{" "}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </AccordionHeader>
                                    <AccordionBody
                                      accordionId={`project-detail-${index}`}>
                                      <Row className="mb-1">
                                        <Col
                                          lg={selectedDevelopMent === "FE" ? 3 : 4}
                                          sm={6}>
                                          <FormGroup>
                                            <Label
                                              className="custom_css_font_color"
                                              for={`projectDetails[${index}]module`}>
                                              Module
                                            </Label>

                                            <Field
                                              type="text"
                                              name={`projectDetails.${index}.module`}
                                              id={`projectDetails.${index}.module`}
                                              placeholder="Enter Module"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name={`projectDetails.${index}.module`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col
                                          lg={selectedDevelopMent === "FE" ? 3 : 4}
                                          sm={6}>
                                          <FormGroup>
                                            <Label
                                              className="custom_css_font_color"
                                              for={`projectDetails.${index}.days`}>
                                              Days
                                            </Label>
                                            <Field
                                              type="number"
                                              name={`projectDetails.${index}.days`}
                                              id={`projectDetails.${index}.days`}
                                              placeholder="Enter Days"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name={`projectDetails.${index}.days`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                        </Col>
                                        {selectedDevelopMent === "FE" && (
                                        <Col md={3} lg={3} sm={6}>
                                          <FormGroup>
                                            <Label
                                              className="custom_css_font_color"
                                              for={`projectDetails.${index}.screen`}>
                                              Screen
                                            </Label>
                                            <Field
                                              type="number"
                                              name={`projectDetails.${index}.screen`}
                                              id={`projectDetails.${index}.screen`}
                                              placeholder="Enter Screen"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name={`projectDetails.${index}.screen`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                        </Col>
                                        )}
                                        <Col
                                          lg={selectedDevelopMent === "FE" ? 3 : 4}
                                          sm={6}>
                                          <FormGroup>
                                            <Label
                                              className="custom_css_font_color"
                                              for={`projectDetails.${index}.apis`}>
                                              Api
                                            </Label>
                                            <Field
                                              type="number"
                                              name={`projectDetails.${index}.apis`}
                                              id={`projectDetails.${index}.apis`}
                                              placeholder="Enter Api"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name={`projectDetails.${index}.apis`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <Row className="mb-3">
                                        <Col lg={12}>
                                          <div className="inputPadding">
                                            <Label
                                              for={`projectDetails.${index}.remark`}
                                              className="form-label custom_css_font_color">
                                              <strong>Api EndPoints</strong>
                                            </Label>
                                            <CKEditor
                                              name={`projectDetails.${index}.apiEndPoints`}
                                              editor={ClassicEditor}
                                              data={user.apiEndPoints}
                                              config={customToolbarConfig}
                                              onChange={(event, editor) => {
                                                const data = editor?.getData();
                                                const textContent =
                                                  data?.replace(/<[^>]*>/g, "");
                                                if (
                                                  textContent?.length <=
                                                  maxCharacterLimit
                                                ) {
                                                  arrayHelpers.replace(index, {
                                                    ...user,
                                                    apiEndPoints: data,
                                                  });
                                                }
                                              }}
                                            />
                                            <p className="custom_css_font_color">
                                              Character Count:{" "}
                                              {
                                                user.apiEndPoints?.replace(
                                                  /<[^>]*>/g,
                                                  ""
                                                )?.length
                                              }{" "}
                                              / {maxCharacterLimit}
                                            </p>
                                            {user.apiEndPoints?.replace(
                                              /<[^>]*>/g,
                                              ""
                                            )?.length >= maxCharacterLimit && (
                                              <p className="validateError">
                                                Character limit exceeded!
                                              </p>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </AccordionBody>
                                  </AccordionItem>
                                ))}
                              </Accordion>
                              <Button
                                type="button"
                                color="success"
                                onClick={() =>
                                  arrayHelpers.push({
                                    topicName: "",
                                    link: "",
                                    hour: "",
                                  })
                                }>
                                + Module
                              </Button>
                            </div>
                          )}
                        />
                      </CardBody>
                    </Card>
                    <div className="hstack gap-2 justify-content-end">
                      <Link to="/itp-project">
                        <button type="button" className="btn btn-ghost-success">
                          <i className="ri-close-fill align-bottom"></i> Close
                        </button>
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        {itpProjectId === undefined ? "Submit" : "Update"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  );
};

export default ITPProjectDetails;
