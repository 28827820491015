import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "./particlesAuth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/logo/shivlogo.png";
import withRouter from "../../components/common/withRouter";
import { login } from "../../api/loginApi";
import { RequiredField } from "../../utils/helpers/validationMasseges";
import { ResponseStatusEnum } from "../../utils/helpers/enums";

const Login = (props) => {
  const history = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(RequiredField("Email"))
        .matches(emailRegex, "Please enter valid email address."),
      password: Yup.string().required(RequiredField("Password")),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      login(values)
        .then((response) => {
          if (response.data.statusCode === ResponseStatusEnum.SUCCESS) {
            history("/");
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        })
        .finally(() => setIsLoading(false));
    },
  });

  document.title = "SignIn";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
            <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="30" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                  </div>

                  <div className="p-2 mt-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <Label className="form-label" htmlFor="password-input">
                          Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                          <button
                            className={`btn btn-link position-absolute ${
                              validation.errors.password ? "err" : "end-0"
                            } top-0 text-decoration-none text-muted`}
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Row>
                          <Col>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                              disabled={isLoading}
                              color="success"
                              className="btn btn-success w-100"
                              type="submit">
                              {isLoading ? (
                                  <Spinner size="sm" className="me-2" />
                              ) : (
                                "Login"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default withRouter(Login);
